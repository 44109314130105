<template>
  <v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content">
    <template v-slot:activator="{ on, attrs }">
      <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
        <v-avatar size="40px" v-bind="attrs" color="primary" class="v-avatar-light-bg primary--text" v-on="on">
          <v-img v-if="$store.state.user.avatar" :src="$store.state.user.avatar"></v-img>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
          <v-avatar size="40px" color="primary" class="v-avatar-light-bg primary--text" @click="doSomething">
            <v-img v-if="$store.state.user.avatar" :src="$store.state.user.avatar"></v-img>
          </v-avatar>
        </v-badge>
        <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
          <span class="text--primary font-weight-semibold mb-n1">{{ $store.state.user.firstname }}</span>
        </div>
      </div>
      <!-- Roll veranderen -->
      <template>
        <v-divider class="mb-2"></v-divider>
        <role-switch />
      </template>

      <!-- Profile -->
      <v-list-item to="/profile">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('routes.profile') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item @click="logoutOnClick">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('routes.logout') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
//Dit component is het te vinden rechtboven in de appbar. Het bestaat uit de profiel foto van de gebruiker met hierin een aantal opties
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiFlag,
} from '@mdi/js'

import RoleSwitch from '@/components/RoleSwitch.vue'
import { computed } from '@vue/composition-api'

import axios from '@/plugins/axios'
import store from '@/store'
import router from '@/router'
import i18n from '@/plugins/i18n'

export default {
  components: {
    RoleSwitch,
  },
  setup() {
    const logoutOnClick = () => {
      axios('logout').finally(() => {
        store.commit('user/logout')
        router.push('/login')
      })
    }

    const doSomething = () => {
      if (store.getters['user/isAdmin']) {
        store.commit('user/user', { avatar: 'https://c.tenor.com/yheo1GGu3FwAAAAC/rick-roll-rick-ashley.gif' })
      }
    }

    const currentRoleName = computed(() => {
      let result = null
      store.state.user.roles.forEach(role => {
        if (role.id === store.state.user.current_role_id) {
          role.role_translation.forEach(translation => {
            if (translation.locale === i18n.locale) {
              result = translation.name
            }
          })
        }
      })

      return result
    })

    return {
      logoutOnClick,
      doSomething,
      currentRoleName,
      RoleSwitch,
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiFlag,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
