import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import i18n from '@/plugins/i18n'

const PASSWORD = "pw"
const TWOFACTOR = "2f"

Vue.use(VueRouter)

const routes =
  [
    {
      path: '/',
      redirect: 'login',
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/pages/Login.vue'),
      meta: {
        layout: 'blank',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot_password',
      component: () => import('@/pages/Forgotpassword.vue'),
      meta: {
        layout: 'blank',
      },
    },
    {
      path: '/login/identify/callback',
      name: 'identify_callback',
      component: () => import('@/pages/IdentifyCallback.vue'),
      meta: {
        layout: 'blank',
      },
    },
    {
      path: '/twofactor/login',
      name: 'two_factor.login',
      component: () => import('@/pages/TwoFactorLogin.vue'),
      meta: {
        required_auth: PASSWORD,
        layout: 'blank',
        "2fa_route": true
      },
    },
    {
      path: '/twofactor/activate',
      name: 'two_factor.activate',
      component: () => import('@/pages/TwoFactorActivate.vue'),
      meta: {
        required_auth: PASSWORD,
        layout: 'blank',
        "2fa_route": true
      },
    },
    {
      path: '/reset_2fa',
      name: 'reset_2fa',
      component: () => import('@/pages/TwoFactorReset.vue'),
      meta: {
        layout: 'blank',
        required_auth: PASSWORD,
        "2fa_route": true
      },
    },
    {
      path: '/registration',
      name: 'registration',
      component: () => import('@/pages/Registration.vue'),
      meta: {
        layout: 'blank',
      },
    },
    {
      path: '/personify_accepted',
      name: 'personify_accepted',
      component: () => import('@/pages/PersonificationAccepted.vue'),
      meta: {
        layout: 'blank',
        required_auth: PASSWORD,
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/pages/profile/Profile.vue'),
      meta: {
        layout: 'content',
        required_auth: TWOFACTOR,
      },
    },
    {
      path: '/roles',
      name: 'roles.index',
      component: () => import('@/pages/role/Roles.vue'),
      meta: {
        layout: 'content',
        required_auth: TWOFACTOR,
      },
    },
    {
      path: '/roles/add',
      name: 'roles.add',
      component: () => import('@/pages/role/RoleAdd.vue'),
      meta: {
        layout: 'content',
        required_auth: TWOFACTOR,
        navActiveLink: 'roles.index'
      },
    },
    {
      path: '/roles/edit/:id',
      name: 'roles.edit',
      component: () => import('@/pages/role/RoleEdit.vue'),
      meta: {
        layout: 'content',
        required_auth: TWOFACTOR,
        navActiveLink: 'roles.index'
      },
    },
    {
      path: '/rights',
      name: 'rights',
      component: () => import('@/pages/rights/Rights.vue'),
      meta: {
        layout: 'content',
        required_auth: TWOFACTOR,
      },
    },
    {
      path: '/contenttypes',
      name: 'contenttypes.index',
      component: () => import('@/pages/contenttypes/Contenttypes.vue'),
      meta: {
        layout: 'content',
        required_auth: TWOFACTOR,
      },
    },
    {
      path: '/contenttypes/edit/:api_identifier',
      name: 'contenttypes.edit',
      component: () => import('@/pages/contenttypes/contenttypefields/ContenttypeFields.vue'),
      meta: {
        layout: 'content',
        required_auth: TWOFACTOR,
        navActiveLink: 'contenttypes.index'
      },
    },
    {
      path: '/contenttype/entries',
      name: 'contenttype_entries.index',
      component: () => import('@/pages/contenttype_entries/Contententries.vue'),
      meta: {
        layout: 'content',
        required_auth: TWOFACTOR,
      },
    },
    {
      path: '/contenttype/:api_identifier/entries/add',
      name: 'contenttype_entries.add',
      component: () => import('@/pages/contenttype_entries/ContententriesAdd.vue'),
      meta: {
        layout: 'content',
        required_auth: TWOFACTOR,
        navActiveLink: 'contenttype_entries.index'
      },
    },
    {
      path: '/contenttype/:api_identifier/entries/edit/:id',
      name: 'contenttype_entries.edit',
      component: () => import('@/pages/contenttype_entries/ContententriesEdit.vue'),
      meta: {
        layout: 'content',
        required_auth: TWOFACTOR,
        navActiveLink: 'contenttype_entries.index'
      },
    },
    {
      path: '/users',
      name: 'users.index',
      component: () => import('@/pages/user/Users.vue'),
      meta: {
        layout: 'content',
        required_auth: TWOFACTOR,
      },
    },
    {
      path: '/users/add',
      name: 'users.add',
      component: () => import('@/pages/user/UserAdd.vue'),
      meta: {
        layout: 'content',
        required_auth: TWOFACTOR,
        navActiveLink: 'users'
      },
    },
    {
      path: '/users/edit/:id',
      name: 'users.edit',
      component: () => import('@/pages/user/UserEdit.vue'),
      meta: {
        layout: 'content',
        required_auth: TWOFACTOR,
        navActiveLink: 'users'
      },
    },
    {
      path: '*',
      redirect: '404',
    },
    {
      path: '/404',
      name: '404',
      component: () => import('@/pages/Error404.vue'),
      meta: {
        layout: 'blank',
      },
    },
  ]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})
router.beforeEach((to, from, next) => { // eslint-disable-line
  const obtainedAuth = store.getters['user/obtainedAuth'];
  const required_auth = to.meta.required_auth;

  // console.log(from.name)
  // console.log(to.name)

  //prevent routing to 2fa related routes when 2fa is disabled
  if (to.meta['2fa_route'] == true) {
    if (process.env.VUE_APP_2FA_ENABLED == "false") {
      next("/")
    }
  }


  //Check if user has the right auth level
  if (to.name === "404") {
    proceed(next);
  } else if (!to.meta.required_auth && !!store.state.user.id) {
    next('/profile')
  } else {
    if (required_auth === undefined) {
      next()

    } else if (required_auth === PASSWORD) {
      obtainedAuth.includes(PASSWORD) ? proceed(next) : redirectToLogin(next, "/login", to.fullPath)
    } else if (required_auth === TWOFACTOR) {
      if (obtainedAuth.includes(TWOFACTOR)) {
        //Check if user has the permission to access this route
        if (store.getters['user/hasVueRoute'](to.name)) {
          next()
        } else {
          next('/')
        }
      } else {
        !store.state.user.preferred_two_factor_authentication_method ? next('/twofactor/activate') : next("/twofactor/login");
      }
    } else {
      next('/')
    }
  }
})

function redirectToLogin(next, to, intented) {
  if (store.state.navigation.intended_route) {
    store.commit("navigation/reset_intended_route")
    next(to)
  } else {
    store.commit("navigation/set_intended_route", intented)
    next(to)
  }
}

function proceed(next) {
  let intended_route = store.state.navigation.intended_route
  if (intended_route) {
    store.commit("navigation/reset_intended_route")
    next(intended_route)
  } else {
    next()
  }
}

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = i18n.t('routes.' + to.name) + " | " + process.env.VUE_APP_TITLE;
  });
});

export { PASSWORD, TWOFACTOR }
export default router
