<template>
  <v-menu
    v-if="this.local_roles.length > 1"
    offset-y
    nudge-bottom="22"
    min-width="175"
    left
    :elevation="$vuetify.theme.dark ? 9 : 8"
  >
    <!-- Activator -->
    <template v-slot:activator="{ on, attrs }">
      <v-divider class="my-2"></v-divider>

      <div text class="mx-5" v-bind="attrs" v-on="on">
        <span class="caption mr-1">{{ $t('label.active_role') }}:</span>

        {{
          local_roles[selected_index].translations[$i18n.locale]
            ? local_roles[selected_index].translations[$i18n.locale].name
            : ''
        }}
        <v-icon>{{ mdiChevronDown }}</v-icon>
      </div>
    </template>

    <!-- Options List -->
    <v-list>
      <v-list-item-group v-model="selected_index" mandatory>
        <v-list-item v-for="(role, i) in local_roles" :key="i" @click="selectRole(role)">
          <v-list-item-title>{{ role.translations[$i18n.locale].name }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
/**
 * Met dit component kan de rol worden veranderd van de gebruiker.
 * Bij het wijzigen van de rol zal een request worden gedaan naar laravel met hierin dat de huidige rol is gewijzigd.
 */
import { mdiChevronDown } from '@mdi/js'
export default {
  mounted() {
    this.local_roles = JSON.parse(JSON.stringify(this.$store.state.user.roles))
    for (let i = 0; i < this.local_roles.length; i++) {
      if (this.local_roles[i].id == this.$store.state.user.current_role_id) {
        this.selected_index = i
        i = this.local_roles.length
      }
    }
  },
  data() {
    return {
      local_roles: [],
      selected_index: '',
      mdiChevronDown,
    }
  },
  methods: {
    selectRole: function (role) {
      //TODO dat deze kan worden gewijzigd in edit user
      this.$http
        .post('users/self', { current_role_id: role.id })
        .then(res => {
          this.$store.commit('user/user', res.data)
          if (!this.hasVueRoute(this.$route.name)) {
            this.$router.push('/profile')
          }
        })
        .catch(() => {
          this.$store.commit('user/logout')
          this.$router.push('/login')
        })
    },
  },
  computed: {
    roles: function () {
      return this.$store.state.user.roles
    },
  },
  watch: {
    roles: function (newValue) {
      //prevent references are being passed to local_roles
      this.local_roles = JSON.parse(JSON.stringify(newValue))
    },
  },
}
</script>
