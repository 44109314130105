import Vue from "vue";
import i18n from '@/plugins/i18n'


Vue.filter("capitalizeFirst", str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
});

Vue.filter("date", str => {
    if (str) {
        return new Date(str).toLocaleString()
    }
});

Vue.filter("translated", arg => {
    if (Array.isArray(arg)) {
        for (let i = 0; i < arg.length; i++) {
            if (arg[i].locale == i18n.locale) {
                return arg[i].name || arg[i].value
            }
        }
    } else {
        return arg || '?'
    }
});


Vue.filter("currency", str => {
    const formatter = new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2
    });

    return formatter.format(str);
});

Vue.filter("bool", str => {
    if (str) return i18n.t("yes");
    return i18n.t("no");
});

Vue.filter("file_size", bytes => {
    if (bytes > 99999) {
        return (Math.round((bytes / 1000000) * 10) / 10) + "MB"
    } else {
        return Math.round((bytes / 1000) * 10) / 10 + "KB"
    }
});
