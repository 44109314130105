export default {
    namespaced: true,
    state: {
        shallContentShowOverlay: false,
        selectedContenttype: ''
    },

    mutations: {
        TOGGLE_CONTENT_OVERLAY(state, value) {
            state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
        },
        setSelectedContenttype(state, value) {
            state.selectedContenttype = value
        }
    },
    actions: {},
}
