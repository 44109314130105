<template>
  <component :is="resolveLayoutVariant" :class="`skin-variant--${appSkinVariant}`">
    <transition :name="appRouteTransition" mode="out-in" appear>
      <router-view :key="router_key.counter"></router-view>
    </transition>
    <v-snackbar v-model="snackbar.model">
      {{ $t(snackbar.text) }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.model = false">{{ $t('common.close') }}</v-btn>
      </template>
    </v-snackbar>
  </component>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { computed, watch, onBeforeMount, reactive, ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { useLayout } from '@core/layouts/composable/useLayout'
import i18n, { loadLanguageAsync } from './plugins/i18n'

// Layouts
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'

// Dynamic vh
import useDynamicVh from '@core/utils/useDynamicVh'

import axios from '@/plugins/axios'
import store from '@/store'

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
  },

  setup() {
    onBeforeMount(() => {
      loadLanguageAsync(store.getters['i18n/lang'])
      axios('/navigation')
        .then(res => {
          store.commit('navigation/navigation', res.data)
        })
        .catch(() => {
          //Server down
        })
      //Once the application starts, try the jwt to see if its valid
      //When its valid the user data will be set
      if (store.state.user.id) {
        axios('/users/self')
          .then(res => {
            store.commit('user/user', res.data)
          })
          .catch(() => {
            store.commit('user/logout')
          })
      }
    })

    const { route } = useRouter()

    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta?.layout === 'blank') return 'layout-blank'
      if (route.value.meta?.layout === 'content') return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    const snackbar = reactive({
      text: '',
      color: '',
      model: false,
    })

    const first_render = reactive({
      bool: true,
    })

    const router_key = reactive({
      counter: 1,
    })

    //Refresh router whenever the role changes
    watch(
      () => store.state.user.current_role_id,
      (newValue, oldValue) => {
        if (oldValue != 0 && newValue != 0) {
          router_key.counter++
        }
      },
    )

    watch(
      () => i18n.locale,
      () => {
        if (!first_render.bool) {
          router_key.counter++
        } else {
          first_render.bool = false
        }

        if (route.value.name) {
          document.title = i18n.t('routes.' + route.value.name) + ' | ' + process.env.VUE_APP_TITLE
        }
      },
    )

    watch(
      () => store.getters['notification/snackbar'].toggle,
      () => {
        const newValue = store.getters['notification/snackbar']

        snackbar.text = newValue.text
        snackbar.color = newValue.color
        snackbar.model = true
      },
    )

    watch(
      () => store.getters['i18n/lang'],
      (newValue, oldValue) => {
        loadLanguageAsync(newValue)
      },
    )

    useDynamicVh()

    return {
      snackbar,
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
      router_key,
    }
  },
}
</script>
