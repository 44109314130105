import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import i18n from '@/plugins/i18n'
import "@/plugins/filters";

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    hasVueRoute: permission => {
      return store.getters['user/hasVueRoute'](permission)
    },
    hasLaravelRoute: permission => {
      return store.getters['user/hasLaravelRoute'](permission)
    },
    translated: (arg) => {
      if (typeof (arg) == 'string') {
        return i18n.t(arg)
      } else if (typeof (arg) == 'object') {
        return i18n.t(arg.key, arg)
      } else {
        return "Invalid translation"
      }
    },
    localeIndex: (array, key = 'key') => {
      let langs = array ? array : store.getters['i18n/langs']
      for (let i = 0; i < langs.length; i++) {
        if (i18n.locale == langs[i][key]) {
          return i
        }
      }
      return -1
    }
  }
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
