export default {
    namespaced: true,
    state: {
        items: [],
        intended_route: null
    },
    getters: {
    },
    mutations: {
        navigation(state, navigation) {
            state.items = generateNavigation(navigation)
        },
        set_intended_route(state, path) {
            state.intended_route = path
        },
        reset_intended_route(state) {
            state.intended_route = null
        },
    },
}
function generateNavigation(navigation = []) {
    let result = []

    navigation.forEach(route => {
        if (route['show_in_menu']) {
            let item_to_add = {
                "title": route['title'],
                "to": route['vue-route'] ? route['vue-route'] : "",
                "icon": route['icon'] ? route['icon'] : "",
            }
            if (route['children']) {
                let children = generateNavigation(route['children'])
                if (children.length > 0) {
                    item_to_add['children'] = children
                }
            }
            result.push(item_to_add)
        }
    })
    return result
}