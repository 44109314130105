<template>
  <v-menu offset-y nudge-bottom="22" min-width="175" left :elevation="$vuetify.theme.dark ? 9 : 8">
    <!-- Activator -->
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" class="d-flex align-center" v-on="on">
        <v-img
          :src="langs[selected_index].image"
          :alt="langs[selected_index].key"
          height="14px"
          width="22px"
          class="me-2"
        ></v-img>
        <span v-if="$vuetify.breakpoint.smAndUp">{{ langs[selected_index].native }}</span>
      </div>
    </template>

    <!-- Options List -->
    <v-list>
      <v-list-item-group v-model="selected_index" mandatory>
        <v-list-item v-for="(lang, i) in langs" :key="i" @click="selectLanguage(lang)">
          <v-img :src="lang.image" height="14px" width="22px" :alt="lang.key" class="me-2"></v-img>
          <v-list-item-title>{{ lang.native }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'LocaleChanger',

  beforeMount() {
    const lang = this.$store.getters['i18n/lang']
    this.langs = this.$store.getters['i18n/langs']
    for (let i = 0; i < this.langs.length; i++) {
      if (lang === this.langs[i].key) {
        this.selected_index = i
        return
      }
    }
  },
  data() {
    return {
      selected_index: 0,
      langs: [],
    }
  },
  methods: {
    selectLanguage: function (selected) {
      this.$store.commit('i18n/lang', selected.key)
    },
  },
}
</script>
