/* eslint-disable */
// prettier-ignore
export default [
  { header: 'Pages' },
  {
    title: 'routes.contenttype_entries.index',
    to: 'contenttype_entries.index',
  },
  {
    title: 'routes.users.index',
    to: 'users.index',
  },
  {
    title: 'routes.users.add',
    to: 'users.add',
  },
  {
    title: 'routes.roles.index',
    to: 'roles.index',
  },
  {
    title: 'routes.roles.add',
    to: 'roles.add',
  },
  {
    title: 'routes.rights',
    to: 'rights',
  },
  {
    title: 'routes.contenttypes.index',
    to: 'contenttypes.index',
  },
  {
    title: 'routes.profile',
    to: 'profile',
  }
]
