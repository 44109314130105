import { PASSWORD, TWOFACTOR } from '@/router'
export default {
    namespaced: true,
    state: {
        id: null,
        email: "",
        firstname: "",
        middlename: "",
        lastname: "",
        avatar: "",

        roles: [],
        current_role_id: 0,
        two_factor_authenticated: false,
        preferred_two_factor_authentication_method: "",
        activated_two_factor_methods: [],
        secondary_email: "",

        personified: false,

        jwt_token: null,
    },
    getters: {
        fullName(state) {
            var full_name = state.firstname
            if (state.middlename) {
                full_name += " " + state.middlename
            }
            full_name += " " + state.lastname
            return full_name
        },
        obtainedAuth(state) {
            var auth = [];
            if (!state.id) {
                return auth
            } else {
                auth.push(PASSWORD)
                if (state.two_factor_authenticated || process.env.VUE_APP_2FA_ENABLED == "false") {
                    auth.push(TWOFACTOR)
                }
            }
            return auth;
        },
        hasVueRoute: (state) => (permission) => {
            let has_permission = false
            state.roles.forEach(role => {
                if (role.id === state.current_role_id) {
                    role['vueroute_role_permissions'].some(item => {
                        if (item.keyname === permission) {
                            has_permission = true;
                            //return true to stop loop
                            return true;
                        }
                    })
                }
            })
            return has_permission
        },
        hasLaravelRoute: (state) => (permission) => {
            let has_permission = false
            state.roles.forEach(role => {
                if (role.id === state.current_role_id) {
                    role['laravelroute_role_permissions'].some(item => {
                        if (item.keyname === permission) {
                            has_permission = true;
                            //return true to stop loop
                            return true;
                        }
                    })
                }
            })
            return has_permission
        },
        isAdmin: (state) => {
            let is_admin = false;
            state.roles.forEach(role => {
                if (role.is_admin == 1) {
                    is_admin = true
                }
            })
            return is_admin
        }
    },


    mutations: {
        user(state, user) {

            for (const [key] of Object.entries(state)) {
                if (key in user) {
                    state[key] = user[key]
                }
            }
            if (!user.current_role_id) {
                state.current_role_id = state.roles[0].id
            }
            console.log('set user', state)
        },
        jwt_token(state, token) {
            state.jwt_token = token;

            console.log('set token', state, token)
        },

        logout(state) {
            state.id = null
            state.email = ""
            state.firstname = ""
            state.middlename = ""
            state.lastname = ""
            state.avatar = ""
            state.two_factor_authenticated = false
            state.preferred_two_factor_authentication_method = ""
            state.activated_two_factor_methods = []
            state.roles = []
            state.current_role_id = 0
            state.secondary_email = ""
            state.personified = false
            state.jwt_token = null
        },
        personified(state, arg) {
            state.personified = arg
        },
    },
    actions: {},
}