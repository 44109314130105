export default {
    namespaced: true,
    state: {
        lang: "en",
        langs: [
            {
                key: 'nl',
                image: require('@/assets/images/flags/NL.svg'),
                native: 'Nederlands',
            },
            {
                key: 'en',
                image: require('@/assets/images/flags/EN.svg'),
                native: 'English',
            },
        ],
    },
    getters: {
        lang: state => {
            return state.lang
        },
        langs: state => {
            return state.langs
        }
    },
    mutations: {

        lang(state, lang) {
            state.lang = lang
        },
    },
    actions: {},
}
