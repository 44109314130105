export default {
    namespaced: true,
    state: {
        toggle: false,
        text: "",
        //color is used but we might want to config it later
        color: ""
    },
    getters: {
        snackbar: (state) => {
            return state
        }
    },
    mutations: {
        notify(state, notification) {
            Object.assign(state, {
                toggle: !state.toggle,
                text: notification.text,
                color: notification.color
            })
        },
    },
}