import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import app from './modules/app'
import user from './modules/user';
import notification from './modules/notification';
import navigation from './modules/navigation';
import i18n from './modules/i18n';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

Vue.use(Vuex);

export default new Vuex.Store({
  actions: {},
  modules: {
    user,
    notification,
    navigation,
    appConfig: appConfigStoreModule,
    i18n,
    app,
  },
  plugins: [vuexLocal.plugin]
});
