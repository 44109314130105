import { mdiCog, mdiPuzzleEditOutline, mdiAccountMultiple,mdiTableOfContents } from '@mdi/js'
import store from '@/store';

const icons = {
  'cog': mdiCog,
  'puzzle_edit_outline': mdiPuzzleEditOutline,
  'table_of_contents': mdiTableOfContents,
  'account_multiple': mdiAccountMultiple,
}


export default function (current_role_id) {
  return getRoutes(store.state.navigation.items)
}

function getRoutes(routes) {
  let result = [];
  routes.forEach(item => {
    if (item.children) {
      let children = getRoutes(item.children)
      if (children.length > 0) {
        result.push({ 'title': item.title, 'icon': icons[item.icon], 'to': item.to, 'children': children })
      }
    }
    else if (store.getters['user/hasVueRoute'](item.to)) {
      let item_to_add = { title: item.title, icon: icons[item.icon], to: item.to }
      result.push(item_to_add)
    }
  })
  return result
}
