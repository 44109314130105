<template>
  <v-tooltip bottom v-if="$store.state.user.personified">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="error"
        outlined
        class="mx-6"
        @click="stopPersonification"
        v-bind="attrs"
        v-on="on"
        :icon="$vuetify.breakpoint.mobile"
      >
        <v-icon v-if="$vuetify.breakpoint.mobile">{{ mdiCardAccountDetailsOutline }}</v-icon>
        <template v-else>
          {{ $t('personify.personified_as', { name: $store.state.user.firstname }) }}
        </template>
      </v-btn>
    </template>
    <span>{{ $t('personify.stop_personification') }}</span>
  </v-tooltip>
</template>

<script>
import { mdiCardAccountDetailsOutline } from '@mdi/js'
export default {
  name: 'PersonifyMenu',

  data() {
    return {
      mdiCardAccountDetailsOutline: mdiCardAccountDetailsOutline,
    }
  },
  methods: {
    stopPersonification: function () {
      this.$http.post('users/stop_personify')
        .then((res) => {
          this.$store.commit('user/personified', false)
          this.$store.commit('user/user', res.data.user)
          this.$router.push('/profile')
        })
        .catch(err => {
          this.$store.commit('user/logout')
          this.$router.push('/login')
        })
    },
  },
}
</script>
